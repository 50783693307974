<i18n>
{
  "de": {
    "saveButtonLabel": "Speichern",
    "cancelButtonLabel": "Abbrechen",
    "deleteConfirmation": "Wollen Sie dieses Ziel wirklich löschen?",
    "duplicateYearErrorMessage": "Jahr bereits vergeben"
  }
}
</i18n>

<template>
  <li class="target-row">
    <form @keydown.esc="onCancel">
      <div>
        <NumericInput
          v-model="year"
          noFormatting
          int
          :min="minYear"
          :max="maxYear"
          :invalid-values="otherYears"
          :invalid-values-error-message="$t('duplicateYearErrorMessage')"
          :edit="isEditing"
        />
      </div>
      <div class="align-right">
        <NumericInput v-model="co2total" :min="0" :edit="isEditing" :units="'kg CO₂e/m²'" />
      </div>
      <div class="align-right">
        <NumericInput v-model="peTotal" :min="0" :edit="isEditing" :units="'kWh/m²'" />
      </div>
      <div v-if="isEditing">
        <ButtonWrapper>
          <button class="button" type="submit" :disabled="submitDisabled" @click.prevent="onSave">
            {{ $t('saveButtonLabel') }}
          </button>
          <a class="button" href="#" @click.prevent="onCancel">{{ $t('cancelButtonLabel') }}</a>
        </ButtonWrapper>
      </div>
      <div v-else>
        <ListButtonWrapper>
          <ListEditButton v-if="allowEdit" @click="onEdit" />
          <ListDeleteButton v-if="allowEdit" @click="onDelete" />
        </ListButtonWrapper>
      </div>
    </form>
  </li>
</template>

<script>
import { mapActions } from 'vuex'

import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper.vue'
import ListEditButton from '@/components/shared/lists/ListEditButton.vue'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'

export default {
  components: {
    ButtonWrapper,
    ListButtonWrapper,
    ListEditButton,
    ListDeleteButton,
    NumericInput,
  },

  props: {
    allowEdit: Boolean,
    value: Object,
    otherTargets: Array,
    edit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      year: null,
      co2total: null,
      peTotal: null,
      minYear: 2000,
      maxYear: 2100,
      editing: false,
    }
  },

  computed: {
    isEditing() {
      return this.edit || this.editing
    },

    isCreate() {
      return Boolean(this.value && !this.value.id)
    },

    submitDisabled() {
      return (
        this.year === null ||
        this.year < this.minYear ||
        this.year > this.maxYear ||
        this.otherYears.includes(this.year) ||
        this.co2total < 0 ||
        this.peTotal < 0 ||
        typeof this.co2total !== 'number' ||
        typeof this.peTotal !== 'number' ||
        typeof this.year !== 'number'
      )
    },

    otherYears() {
      return (this.otherTargets || []).map((t) => t.year)
    },
  },

  watch: {
    value(value) {
      this.updateRow(value)
    },
  },

  created() {
    this.updateRow(this.value)
  },

  methods: {
    ...mapActions({
      addTarget: 'portfolio/addTarget',
      updateTarget: 'portfolio/updateTarget',
      deleteTarget: 'portfolio/deleteTarget',
    }),

    updateRow(row) {
      const { year, co2total, pe_total: peTotal } = row || {}

      this.year = year
      this.co2total = co2total
      this.peTotal = peTotal
    },

    onSave() {
      const { year, co2total, peTotal } = this
      const { portfolio_id: portfolioId } = this.value || {}

      const target = {
        portfolio_id: portfolioId,
        year,
        co2total,
        pe_total: peTotal,
      }
      this.editing = false
      if (this.isCreate) {
        this.addTarget(target)
        this.$emit('input', null)
      } else {
        this.updateTarget({ id: this.value.id, target: target })
      }
    },

    onCancel() {
      this.editing = false
      this.updateRow(this.value)
      if (this.isCreate) {
        this.$emit('input', null)
      }
    },

    onEdit() {
      this.editing = true
    },

    onDelete() {
      const confirmationText = this.$t('deleteConfirmation')
      if (confirm(confirmationText)) {
        const { id, portfolio_id: portfolioId } = this.value || {}

        const target = {
          id,
          portfolio_id: portfolioId,
        }
        this.editing = false
        this.deleteTarget(target)
      }
    },
  },
}
</script>

<style lang="scss">
.target-row > form {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;

  & > div {
    flex: 1;
  }
}

.target-row .align-right {
  text-align: right;
  justify-content: flex-end;
}
</style>
