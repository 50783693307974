<i18n>
{
  "de": {
    "targetsTitle": "Ziele",
    "yearTitle": "Jahr",
    "co2targetTitle": "Ziel Scope 1-2 Emissionen",
    "peTargetTitle": "Ziel Primärenergie",
    "noTargets": "Dieses Portfolio enthält keine Ziele",
    "addButtonLabel": "Neues Ziel hinzufügen"
  }
}
</i18n>

<template>
  <div class="targets-section">
    <DetailList v-if="hasTargets || target" has-header>
      <template #header>
        <span>{{ $t('yearTitle') }}</span>
        <span class="align-right">{{ $t('co2targetTitle') }}</span>
        <span class="align-right">{{ $t('peTargetTitle') }}</span>
        <span></span>
      </template>

      <EditTargetRow
        v-for="target in targets"
        :key="target.id"
        v-model="target.item"
        :allow-edit="getPortfolioPermission('EDIT_TARGETS')"
        :other-targets="otherTargets(target.item)"
      />
      <EditTargetRow v-if="target" v-model="target" :other-targets="allTargets" :edit="true" />
    </DetailList>

    <p v-else>{{ $t('noTargets') }}</p>

    <ButtonWrapper>
      <button v-if="!target && getPortfolioPermission('EDIT_TARGETS')" class="button" @click.prevent="add">
        {{ $t('addButtonLabel') }}
      </button>
    </ButtonWrapper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'

import EditTargetRow from './EditTargetRow.vue'

export default {
  components: {
    ButtonWrapper,
    EditTargetRow,
    DetailList,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      targets: null,
      target: null,
    }
  },

  computed: {
    ...mapGetters({
      getPortfolioPermission: 'permissions/getPortfolioPermission',
    }),

    hasTargets() {
      return Boolean(this.targets) && this.targets.length > 0
    },

    allTargets() {
      return (this.targets || []).map(({ item }) => item)
    },
  },

  watch: {
    value(value) {
      this.updateTargets(value)
    },
  },

  created() {
    this.updateTargets(this.value)
  },

  methods: {
    updateTargets(targets) {
      this.targets = (targets || []).map((item) => ({ item })).sort((a, b) => a.item.year - b.item.year)
    },

    otherTargets(target) {
      return this.allTargets.filter((t) => t.id !== target.id)
    },

    add() {
      const year = this.hasTargets
        ? this.allTargets[this.allTargets.length - 1].year + 10
        : new Date().getFullYear() + 10
      this.target = {
        portfolio_id: this.portfolio.id,
        year,
        co2total: 20,
        pe_total: 130,
      }
    },
  },
}
</script>

<style>
.targets-section .align-right {
  text-align: right;
  justify-content: flex-end;
}

.targets-section .detail-list {
  margin-bottom: var(--spacing-m);
}
</style>
